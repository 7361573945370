import { GeneralCollection } from 'src/models';
import { http } from '../http-client';
import {
  IncidenceModelService,
  IncidenceStatusModelService,
  PostIncidenceCommentModelService,
  PostIncidenceFileModelService,
  PostIncidenceModelService,
} from './model-service';
import { DocumentPropsModelService } from '../blob';

const getIncidence = (incidenceId: string | undefined) => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<IncidenceModelService>>(`incidence/${incidenceId}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

const getIncidenceStates = () => {
  const controller = new AbortController();

  return {
    call: http.get<GeneralCollection<IncidenceStatusModelService>>('incidence/states', {
      signal: controller.signal,
    }),
    controller,
  };
};

const postIncidence = (request: PostIncidenceModelService) => {
  const controller = new AbortController();

  return {
    call: http.post('incidence', request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const postIncidenceComment = (request: PostIncidenceCommentModelService) => {
  const controller = new AbortController();

  return {
    call: http.post(`incidence/${request.incidenceId}/comments`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const putIncidenceStatus = (request: { incidenceId: string; incidenceStatusId: string }) => {
  const controller = new AbortController();

  return {
    call: http.put('incidence/status', request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const deleteIncidence = (incidenceId: string) => {
  const controller = new AbortController();

  return {
    call: http.delete(`incidence/${incidenceId}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

const getDocumentProps = () => {
  const controller = new AbortController();

  return {
    call: http.get<DocumentPropsModelService>('incidence/getAllowedDocumentProps', { signal: controller.signal }),
    controller,
  };
};

const postIncidenceImage = (request: PostIncidenceFileModelService) => {
  const controller = new AbortController();

  return {
    call: http.post(`incidence/file`, request, {
      signal: controller.signal,
    }),
    controller,
  };
};

const deleteFile = (blobId: string) => {
  const controller = new AbortController();

  return {
    call: http.delete(`incidence/file`, {
      signal: controller.signal,
      data: { blobId },
    }),
    controller,
  };
};

export const incidenceServices = {
  getIncidence,
  getIncidenceStates,
  postIncidence,
  postIncidenceComment,
  putIncidenceStatus,
  deleteIncidence,
  getDocumentProps,
  deleteFile,
  postIncidenceImage,
};
