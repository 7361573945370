import ShareIcon from '@mui/icons-material/Share';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { IsCommercial } from 'src/utilities';
import { HmyBtn } from '../hmy-btn';
import { ROUTE_SIGN_ON } from '../routes';
import './sign-on-link.scss';
import { FormattedMessage } from 'react-intl';

const SignOnLink: FC = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);

  const url = `${window.location.origin}${ROUTE_SIGN_ON.replace(':commercial', btoa(currentUser.id))}`;
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      dispatch(setMessage(SuccessMessage('success.copyLink', true)));
    } catch (err) {
      dispatch(setMessage(ErrorMessage('error.copyLink', true)));
    }
  };

  if (!IsCommercial(currentUser)) {
    return null;
  }
  return (
    <div className="sign-on-link-container">
      <div className="sign-on-link-header">
        <ShareIcon style={{ width: '20px' }} /> <FormattedMessage id="shareLink" />
      </div>
      <div className="sign-on-link-body">
        <span>{url}</span>
        <HmyBtn color="secondary" handleClick={copyToClipboard} title="btn.copyLink" fullwith={false} width={250} />
      </div>
    </div>
  );
};
export default SignOnLink;
