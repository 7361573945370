import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTransport } from 'src/hooks/use-transport.hook';
import { BasicOrder } from 'src/models/orders';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { OrderPrice, calculeOrderPrice } from '../calcule-order-price.helpers';
import { formatPrice } from '../format-price.helpers';
import '../order.scss';

type OrderTotalsProps = {
  orderData: BasicOrder | BasicShoppingOrder;
  orderEdited: boolean;
  urgent?: boolean;
};

const OrderTotals: FC<OrderTotalsProps> = ({ orderData, orderEdited, urgent = false }) => {
  const [orderPrice, setOrderPrice] = useState<OrderPrice>();
  const [transportCostView, setTransportCostView] = useState(0);
  const { transportCost } = useTransport({ ...orderData, urgent });

  useEffect(() => {
    if (orderData) {
      const tempPrice = calculeOrderPrice(orderData);
      setOrderPrice({
        ...tempPrice,
        subTotal: orderData.additionalProjects
          ? (orderData.additionalProjects.length + 1) * tempPrice.subTotal
          : tempPrice.subTotal,
      });
    }
    setTransportCostView(orderEdited ? transportCost : orderData.shippingPrice);
  }, [orderData, transportCost, orderEdited]);

  if (!orderPrice) {
    return <></>;
  }

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="orderTotalPrice" />
      </Typography>
      <Box className="order-totals">
        <div className="order-totals-row">
          <FormattedMessage id="materialPrice" />
          <div className="order-totals-row-line" />
          <div>{formatPrice(orderPrice.subTotal)}</div>
        </div>
        <div className="order-totals-row">
          <FormattedMessage id="transportPrice" />
          <div className="order-totals-row-line" />
          {orderData.additionalProjects && orderData.additionalProjects.length > 0 ? (
            <FormattedMessage id="orderMultipleProjectTransport" />
          ) : transportCostView === 0 ? (
            <FormattedMessage id="orderSingleProjectTransport" />
          ) : (
            <div>{formatPrice(transportCostView)}</div>
          )}
        </div>
        <div className="order-totals-total">
          <FormattedMessage
            id="totalPrice"
            values={{
              value:
                orderData.additionalProjects && orderData.additionalProjects.length > 0
                  ? formatPrice(orderPrice.subTotal)
                  : formatPrice(orderPrice.subTotal + transportCostView),
            }}
          />
        </div>
        <div className="order-totals-total-vat">
          <FormattedMessage id="noVATIncluded" />
        </div>
      </Box>
    </>
  );
};

export default OrderTotals;
