import { Box, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Captcha, HmyBtn } from 'src/components';
import { CreateEasyUserDto } from 'src/dtos';
import { useFetchAndLoad } from 'src/hooks';
import { userServices } from 'src/services';
import LogoMiHmyAzul from '../../assets/images/logo_mihmy_azul.svg';
import { userCustomerError } from '../management/user-new';
import './sign-on.scss';

const SignOn: FC = () => {
  const [commercialId, setCommercialId] = useState<string | null>(null);
  const [isVerifiedRecovery, setIsVerifiedRecovery] = useState<boolean>(false);
  const [resetCaptcha, setResetCaptcha] = useState<boolean>(false);
  const [customerErrors, setCustomerErrors] = useState<userCustomerError>({
    name: null,
    surname: null,
    userName: null,
    commercial: null,
    total: 0,
  });
  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
  });
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const { commercial } = useParams();
  const { loading, callEndpoint } = useFetchAndLoad();
  const createEasyUser = async (userData: CreateEasyUserDto) =>
    await callEndpoint(userServices.createEasyUser(userData));

  useEffect(() => {
    const getUserExist = async (id: string) => await callEndpoint(userServices.getUserExist(id));
    if (commercial) {
      const commercialId: string = atob(commercial);
      getUserExist(commercialId).then(({ data }) => {
        if (data) {
          setCommercialId(commercialId);
        }
      });
    }
  }, [commercial]);

  const validateEmailCustomer = (email: string): number => {
    const regex = RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[^@]+.[a-zA-Z.!#$%'*+/=?^_`{|}~-]{2,}$");
    let totalErrors: number =
      (customerErrors.name === null ? 0 : 1) +
      (customerErrors.surname === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (email === null || email.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        userName: 'error.required',
        total: totalErrors + 1,
      });

      return totalErrors + 1;
    } else if (!regex.test(email.trim()) || email.trim().endsWith('@hmygroup.com')) {
      setCustomerErrors({
        ...customerErrors,
        userName: 'error.email',
        total: 1,
      });

      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        userName: null,
        total: totalErrors,
      });

      return 0;
    }
  };

  const validateName = (name: string): number => {
    let totalErrors: number =
      (customerErrors.userName === null ? 0 : 1) +
      (customerErrors.surname === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (name === null || name.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        name: 'error.required',
        total: totalErrors + 1,
      });
      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        name: null,
        total: totalErrors,
      });
      return 0;
    }
  };

  const validateSurname = (surname: string): number => {
    let totalErrors: number =
      (customerErrors.userName === null ? 0 : 1) +
      (customerErrors.name === null ? 0 : 1) +
      (customerErrors.commercial === null ? 0 : 1);
    if (surname === null || surname.trim() === '') {
      setCustomerErrors({
        ...customerErrors,
        surname: 'error.required',
        total: totalErrors + 1,
      });
      return 1;
    } else {
      setCustomerErrors({
        ...customerErrors,
        surname: null,
        total: totalErrors,
      });
      return 0;
    }
  };

  const validateForm = () => {
    return Object.values(values).every((value) => !(value === null || value.trim() === ''));
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.name !== null) {
      validateName(event.target.value);
    }
    setValues({
      ...values,
      name: event.target.value,
    });
  };

  const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.surname !== null) {
      validateSurname(event.target.value);
    }
    setValues({
      ...values,
      surname: event.target.value,
    });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customerErrors.userName !== null) {
      validateEmailCustomer(event.target.value);
    }
    setValues({
      ...values,
      email: event.target.value,
    });
  };

  const handleSubmit = () => {
    validateName(values.name);
    validateSurname(values.surname);
    validateEmailCustomer(values.email);
    if (customerErrors.total === 0 && isVerifiedRecovery && commercialId) {
      const data = {
        language: 'es',
        role: 'Cliente',
        boss: null,
        customer: null,
        users: [],
        showMateriaLab: true,
        showRoomSpain: true,
        showRoomFrance: true,
        showRoomEuroshop: true,
        sectors: [],
        products: [],
        commercial: {
          id: commercialId,
          email: '',
          fullName: '',
          isCustomer: false,
        },
      };
      createEasyUser({
        ...data,
        ...values,
        userName: values.email,
        //commercial: { id: commercialId },
      }).then((response) => {
        if (response) {
          setIsUserCreated(true);
        }
      });
    }
  };

  if (commercialId === null) {
    return (
      <Box className="sign-on-container">
        <Box className="sign-on-topbar-container">
          <Box className="sign-on-topbar-container-logo">
            <img src={LogoMiHmyAzul} alt="logo" />
          </Box>
        </Box>
        <Box className="sign-on-container-content">
          <FormattedMessage id="error.CreateUserLink" />
        </Box>
      </Box>
    );
  }

  if (isUserCreated) {
    return (
      <Box className="sign-on-container">
        <Box className="sign-on-topbar-container">
          <Box className="sign-on-topbar-container-logo">
            <img src={LogoMiHmyAzul} alt="logo" />
          </Box>
        </Box>
        <Box className="sign-on-container-content">
          <FormattedMessage id="success.UserCreated" />
        </Box>
      </Box>
    );
  }

  return (
    <Box className="sign-on-container">
      <Box className="sign-on-topbar-container">
        <Box className="sign-on-topbar-container-logo">
          <img src={LogoMiHmyAzul} alt="logo" />
        </Box>
      </Box>
      <Box className="sign-on-container-content">
        <TextField
          label={<FormattedMessage id="name" />}
          variant="standard"
          value={values.name}
          onChange={handleChangeName}
          onBlur={(e) => validateName(values.name)}
          error={customerErrors.name !== null}
          helperText={customerErrors.name !== null ? <FormattedMessage id={customerErrors.name} /> : null}
          fullWidth
          inputProps={{ style: { fontSize: 20 } }}
        />
        <TextField
          label={<FormattedMessage id="surnames" />}
          variant="standard"
          value={values.surname}
          onChange={handleChangeSurname}
          onBlur={(e) => validateSurname(values.surname)}
          error={customerErrors.surname !== null}
          helperText={customerErrors.surname !== null ? <FormattedMessage id={customerErrors.surname} /> : null}
          fullWidth
          inputProps={{ style: { fontSize: 20 } }}
        />
        <TextField
          label={<FormattedMessage id="email" />}
          variant="standard"
          value={values.email}
          onChange={handleChangeEmail}
          onBlur={(e) => validateEmailCustomer(values.email)}
          error={customerErrors.userName !== null}
          helperText={customerErrors.userName !== null ? <FormattedMessage id={customerErrors.userName} /> : null}
          fullWidth
          inputProps={{ style: { fontSize: 22 } }}
        />
        <Captcha
          setIsVerified={setIsVerifiedRecovery}
          setRecaptchaShowed={() => {}}
          language={'es'}
          resetCaptcha={resetCaptcha}
        />
        <HmyBtn
          title="btn.accept"
          width={300}
          fullwith={false}
          color="primary"
          handleClick={handleSubmit}
          disabled={validateForm() === false || customerErrors.total > 0 || !isVerifiedRecovery}
        />
      </Box>
    </Box>
  );
};

export default SignOn;
