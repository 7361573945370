import { ThemeProvider } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtectedRoute from './route.guard';
import { theme } from '../../theme';
import {
  ROUTE_HOME,
  ROUTE_IMPORT_PROJECTS,
  ROUTE_INCIDENCE,
  ROUTE_INSPIRATION,
  ROUTE_LANDING,
  ROUTE_LOGIN,
  ROUTE_MANAGEMENT,
  ROUTE_NEW_ORDER,
  ROUTE_ORDER,
  ROUTE_ORDERS,
  ROUTE_PROFILE,
  ROUTE_PROJECT,
  ROUTE_PROJECTS,
  ROUTE_RECOVER_PASS,
  ROUTE_REGISTER_USER,
  ROUTE_REPORTS,
  ROUTE_SIGN_ON,
  ROUTE_USER_GROUPING,
  ROUTE_USER_GROUPINGS,
  ROUTE_USER_GROUPINGS_NEW,
} from './routes';
import { CookiesComponent, MenuBottom, Navbar, Footer, MessageComponent } from '../';
import { Landing } from 'src/pages/landing';
import { Home } from 'src/pages/home';
import { Projects } from 'src/pages/projects';
import { Groupings } from 'src/pages/groupings';
import { Management } from 'src/pages/management';
import { Login } from 'src/pages/login';
import { Profile } from 'src/pages/profile';
import { OAuth2Page } from 'src/pages/oauth2';
import { currentUserSelector } from 'src/redux/states';
import {
  ADD_GROUPING,
  ADD_PROJECTS,
  READ_GROUPINGS,
  READ_HOME,
  READ_MANAGEMENT,
  READ_ORDERS,
  READ_PROFILE,
  READ_PROJECTS,
  READ_REPORTS,
} from 'src/permissions/permissions';
import { ImportProjects } from 'src/pages/import-projects';
import { Project } from 'src/pages/project';
import { NotFound } from 'src/pages/not-found';
import { GroupingNew } from 'src/pages/grouping-new';
import { Grouping } from 'src/pages/grouping';
import { StaticPassword } from 'src/pages/static-password';
import { Forbidden } from 'src/pages/forbidden';
import { ReportsPage } from 'src/pages/reports';
import { Orders } from 'src/pages/orders';
import { Order } from 'src/pages/order';
import NewOrder from 'src/pages/order/new-order';
import Incidence from 'src/pages/incidence';
import SignOn from 'src/pages/sign-on/sign-on';

const AppRoutes: FunctionComponent = () => {
  const currentUser = useSelector(currentUserSelector);

  const [showFooter, setShowFooter] = useState<boolean>(false);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        <Navbar setShowFooter={setShowFooter} />
        <MessageComponent duration={3000} horizontal="center" vertical="top" />
        <Routes>
          <Route path="/oauth2-callback/:provider/:profile" element={<OAuth2Page />} />
          <Route path={ROUTE_LANDING} element={<Landing />} />
          <Route path={ROUTE_LOGIN} element={<Login />} />
          <Route path={ROUTE_SIGN_ON} element={<SignOn />} />
          <Route path={ROUTE_RECOVER_PASS} element={<StaticPassword isRecoverPass={true} />} />
          <Route path={ROUTE_REGISTER_USER} element={<StaticPassword isRecoverPass={false} />} />

          <Route
            path={ROUTE_INSPIRATION}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_HOME) ? <Home /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_HOME}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_HOME) ? <Home /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_PROJECTS}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_PROJECTS) ? <Projects /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_PROJECT}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_PROJECTS) ? <Project /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_IMPORT_PROJECTS}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(ADD_PROJECTS) ? <ImportProjects /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_ORDERS}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_ORDERS) ? <Orders /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_ORDER}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_ORDERS) ? <Order /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_NEW_ORDER}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_ORDERS) ? <NewOrder /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_INCIDENCE}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_ORDERS) ? <Incidence /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_USER_GROUPINGS}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_GROUPINGS) && currentUser.hasGroupings ? (
                  <Groupings />
                ) : (
                  <Forbidden />
                )}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_USER_GROUPINGS_NEW}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(ADD_GROUPING) ? <GroupingNew /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_USER_GROUPING}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_GROUPINGS) ? <Grouping /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_REPORTS}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_REPORTS) ? <ReportsPage /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={`${ROUTE_MANAGEMENT}/*`}
            element={
              <ProtectedRoute isAllowed={currentUser.id !== ''}>
                {currentUser.permissions.includes(READ_MANAGEMENT) ? <Management /> : <Forbidden />}
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_PROFILE}
            element={
              <ProtectedRoute isAllowed={currentUser.permissions.includes(READ_PROFILE)}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <MenuBottom />
        <Footer showEnabled={showFooter} />
        <CookiesComponent />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export { AppRoutes };
export * from './routes';
