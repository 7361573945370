import { TextField, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HmyFormElement, HmyFormSection } from 'src/components';
import { TypeDocument } from 'src/models';

export type InboxNewFileForm = {
  finalName: string;
  description: string;
  date: string;
  documentType: string;
  size: number;
  isBigFile: boolean;
};

type InboxFileAddFormProps = {
  values: InboxNewFileForm;
  setValues: (values: InboxNewFileForm) => void;
};

const InboxFileAddForm: FC<InboxFileAddFormProps> = ({ values, setValues }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [nameError, setNameError] = useState<string | null>(null);

  const validateName = (name: string): number => {
    if (name === null || name.trim() === '') {
      setNameError('error.required');
      return 1;
    } else {
      setNameError(null);
      return 0;
    }
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (nameError !== null) {
      validateName(event.target.value);
    }
    setValues({
      ...values,
      description: event.target.value,
    });
  };

  return (
    <>
      <HmyFormSection>
        <HmyFormElement isMultiline={false} isFullWidth={false} showTitle={true} title="description">
          <TextField
            label=""
            variant="standard"
            value={values.description}
            onChange={handleChangeDescription}
            onBlur={(e) => validateName(values.description)}
            error={nameError !== null}
            helperText={nameError !== null ? <FormattedMessage id={nameError} /> : null}
            fullWidth
            inputProps={{ style: { fontSize: matches ? 16 : 20 } }}
          />
        </HmyFormElement>
      </HmyFormSection>
    </>
  );
};

export default InboxFileAddForm;
