import { SetUserProjectDto } from 'src/dtos';
import { AddImportProjectDto } from 'src/dtos/projects/add-import-project.dto';
import {
  BasicProject,
  CompleteProject,
  ImportProject,
  ProjectProfile,
  Selectable,
  UserProject,
  VStore,
  VstoreFile,
} from 'src/models';
import {
  BasicProjectModelService,
  CompleteProjectModelService,
  ImportProjectModelService,
  ProjectProfileModelService,
  UserProjectModelService,
  VstoreFileModelService,
  VStoreModelService,
} from 'src/services/projects';
import { customerProjectAdapater } from './customer.adapter';

const userProjectAdapater = (userProject: UserProjectModelService): UserProject => ({
  id: userProject.id,
  email: userProject.email,
  fullName: userProject.fullName,
  profileId: userProject.profile.profileId,
  profileName: userProject.profile.name,
  isDeleted: userProject.isDeleted,
});

const vStoreAdapater = (vstore: VStoreModelService): VStore => ({
  vStoreStatus: vstore.vStoreStatus,
  visibles: vstore.visibles,
});

export const basicProjectAdapter = (basicProject: BasicProjectModelService): BasicProject => {
  let users = basicProject.users.map((x: UserProjectModelService) => {
    return userProjectAdapater(x);
  });

  return {
    projectId: basicProject.projectId,
    description: basicProject.description,
    sourceId: basicProject.sourceId,
    projectNumber: basicProject.projectNumber,
    date: basicProject.date,
    customer: basicProject.customer,
    customerId: basicProject.customerId,
    company: basicProject.company,
    isClosed: basicProject.isClosed,
    isDeleted: basicProject.isDeleted,
    projectName: basicProject.projectName,
    address: basicProject.address,
    users: users,
    saleOrderEnabled: basicProject.saleOrderEnabled,
    catalogueIds: basicProject.catalogueIds,
    totalIncidences: basicProject.totalIncidences,
    minOrderAmount: basicProject.minOrderAmount,
  };
};

export const completeProjectAdapter = (project: CompleteProjectModelService): CompleteProject => {
  let users = project.users.map((x: UserProjectModelService) => {
    return userProjectAdapater(x);
  });

  return {
    projectId: project.projectId,
    description: project.description,
    sourceId: project.sourceId,
    projectNumber: project.projectNumber,
    date: project.date,
    customer: project.customer,
    customerId: project.customerId,
    company: project.company,
    isClosed: project.isClosed,
    isDeleted: project.isDeleted,
    projectName: project.projectName,
    address: project.address,
    users: users,
    vStore: vStoreAdapater(project.vStore),
    permissions: project.permissions,
    saleOrderEnabled: project.saleOrderEnabled,
    catalogueIds: project.catalogueIds,
    totalIncidences: project.totalIncidences,
    minOrderAmount: project.minOrderAmount,
  };
};

export const importProjectAdapter = (importProject: ImportProjectModelService): ImportProject => {
  return {
    projectId: importProject.projectId,
    description: importProject.description,
    sourceId: importProject.sourceId,
    projectNumber: importProject.projectNumber,
    customer: customerProjectAdapater(importProject.customer),
    projectDate: importProject.projectDate,
    users: importProject.users,
  };
};

export const addImportProjectAdapter = (projectid: string, users: string[]): AddImportProjectDto => {
  return {
    projectId: projectid,
    users: users,
  };
};

export const addProjectProfileAdapter = (projectProfile: ProjectProfileModelService): ProjectProfile => {
  return {
    projectProfileId: projectProfile.projectProfileId,
    name: projectProfile.name,
    isSingle: projectProfile.isSingle,
    requiresHighPrivileges: projectProfile.requiresHighPrivileges,
  };
};

export const vstoreFileAdapter = (vstoreFile: VstoreFileModelService): VstoreFile => {
  return {
    urn: vstoreFile.urn,
    source: vstoreFile.source,
    name: vstoreFile.name,
    thumbnail: vstoreFile.thumbnail,
    visible: vstoreFile.visible,
  };
};

export const projectProfileSelectableAdapater = (profile: ProjectProfile): Selectable => ({
  id: profile.projectProfileId,
  text: profile.name.toLowerCase(),
});

export const setUserProjectAdapater = (user: UserProject): SetUserProjectDto => ({
  userId: user.id,
  profileId: user.profileId,
});
