import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { documentPropsAdapter } from 'src/adapters';
import { CreateInboxBlobDto } from 'src/dtos';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { CompleteProject, DocumentProps, ErrorMessage, SuccessMessage } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { DocumentPropsModelService } from 'src/services';
import { orderServices } from 'src/services/orders';
import { getBase64 } from 'src/utilities/get-base64';
import './add-documents.scss';
import InboxFileAdd, { InboxFileAddObject } from './inbox-file-add';
import ViewDocument from './view-document';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';

type AddDocumentsProps = {
  project: CompleteProject;
  files: any;
  setFiles: any;
  orderData?: BasicOrder;
};

const AddDocuments: FC<AddDocumentsProps> = ({ project, files, setFiles, orderData }) => {
  const [orderFiles, setOrderFiles] = useState<any>([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
  const getDocumentProps = async () => await callEndpoint(orderServices.getDocumentProps());
  const deleteDocument = async (fileId: string) => await callEndpoint(orderServices.deleteFile(fileId));
  const dispatch = useDispatch();

  const adaptDocumentProps = (data: DocumentPropsModelService) => {
    let docProps = documentPropsAdapter(data);

    setDocumentProperties(docProps);
  };

  useEffect(() => {
    if (orderData && orderData?.blobs?.length > 0) {
      setOrderFiles(orderData.blobs);
    }
  }, [orderData]);

  // Helper function to process each file and create a DTO
  const processFile = async (file: InboxFileAddObject, projectId: string): Promise<CreateInboxBlobDto> => {
    const base64 = await getBase64(file.file!); // Get base64 asynchronously
    return {
      ...file,
      data: (base64 as string).split(',')[1],
      projectId,
    };
  };

  const createNewInboxBlob = async (files: InboxFileAddObject[]) => {
    const projectId = project.projectId;

    const definitiveRequest: CreateInboxBlobDto[] = await Promise.all(
      files.map((file) => processFile(file, projectId))
    );

    setFiles(definitiveRequest.filter((file) => !file.isBigFile));
  };

  const removeDocument = (blobId: string) => {
    deleteDocument(blobId)
      .then(() => {
        //setFiles(files.filter((file: any) => file.blobId !== blobId));
        dispatch(setMessage(SuccessMessage('success.DeleteFile', true)));
      })
      .catch(() => {
        dispatch(setMessage(ErrorMessage('error.DeleteFile', true)));
      });
  };

  useAsync(
    getDocumentProps,
    adaptDocumentProps,
    () => {},
    () => {},
    []
  );
  if (orderFiles.length > 0) {
    return (
      <div style={{ width: '100%' }}>
        <Typography className="order-title-text">
          <FormattedMessage id="documentsSection" />
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', columnGap: '10px' }}>
          {orderFiles.map((file: { fileId: string; description: string }, index: number) => (
            <>
              <ViewDocument documentId={file.fileId} description={file.description} key={index} />
              <ClearIcon
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  removeDocument(file.fileId);
                  //setFiles(orderFiles.filter((f: any) => f.fileId !== file.fileId));
                  setOrderFiles(orderFiles.filter((f: any) => f.fileId !== file.fileId));
                }}
              />
            </>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Box className="inbox-container">
      <Typography className="order-title-text">
        <FormattedMessage id="documentsSection" />
      </Typography>
      {documentProperties && (
        <InboxFileAdd documentOptions={documentProperties} createNewInboxBlob={createNewInboxBlob} />
      )}
      <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', columnGap: '10px' }}>
        {files.map((file: { fileId: string; description: string }, index: number) => (
          <>
            <ViewDocument documentId={file.fileId} description={file.description} key={index} />{' '}
            <ClearIcon
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => {
                const filterFiles = [...files];
                filterFiles.splice(index, 1);
                setFiles(filterFiles);
              }}
            />
          </>
        ))}
      </div>
    </Box>
  );
};

export default AddDocuments;
