import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Dialog, TextField, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HmyBtn, HmySelectable } from 'src/components';
import { AutocompleteCatalogue } from 'src/components/hmy-selectable-autocomplete/autocomplete-catalogue';
import { CATEGORY_NONE, useCatalogue } from 'src/hooks/use-catalogue.hook';
import { formatPrice } from 'src/pages/order/format-price.helpers';
import { BasicCatalagueModelService, BasicCatalogueLineModelService } from 'src/services/catalogue';
import './hmy-modal-order.scss';
import ImageArticle from './image-article';
import PdfArticle from './pdf-article';

type HmyModalOrderProps = {
  isOpen: boolean;
  close: () => void;
  addItems: (items: BasicCatalogueLineModelService[]) => void;
  projectid: string;
  isUrgentOrder: boolean;
  selectedCatalogues?: string[];
};

const HmyModalOrder: FC<HmyModalOrderProps> = ({
  isOpen,
  close,
  addItems,
  projectid,
  isUrgentOrder,
  selectedCatalogues = [],
}) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [shoppingList, setShoppingList] = useState<BasicCatalogueLineModelService[]>([]);
  const [filteredCatalogues, setFilteredCatalogues] = useState<BasicCatalagueModelService[]>([]);
  const [searchCatalogCategory, setSearchCatalogCategory] = useState<string>('');
  const [searchCatalog, setSearchCatalog] = useState<string>('');
  const [allowsUrgent, setAllowsUrgent] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>('reference');
  const [searchText, setSearchText] = useState<string>('');

  const intl = useIntl();
  const { data, catalogueData, setCatalogueId, catalogueCategories } = useCatalogue(projectid, selectedCatalogues);

  useEffect(() => {
    setCatalogueId(searchCatalog);
    setAllowsUrgent(
      data?.find((catalogue: BasicCatalagueModelService) => catalogue.catalogueId === searchCatalog)?.allowsUrgent ??
        false
    );
  }, [searchCatalog]);

  useEffect(() => {
    !searchCatalogCategory && setSearchCatalogCategory(catalogueCategories[0]);
  }, [catalogueCategories]);

  useEffect(() => {
    if (data && data.length > 0) {
      const catalogues = data.filter(
        (catalogue: BasicCatalagueModelService) =>
          catalogue.transportCategoryName === searchCatalogCategory ||
          (searchCatalogCategory === CATEGORY_NONE && catalogue.transportCategoryName === '')
      );
      setFilteredCatalogues(
        catalogues?.sort((a: BasicCatalagueModelService, b: BasicCatalagueModelService) => a.name.localeCompare(b.name))
      );

      if (catalogues.length > 0) {
        setSearchCatalog(catalogues[0].catalogueId);
        setAllowsUrgent(catalogues[0].allowsUrgent);
      } else if (catalogues.length === 0 && data.length > 0) {
        setSearchCatalog(data[0].catalogueId);
        setAllowsUrgent(data[0].allowsUrgent);
      }
    }
  }, [data, searchCatalogCategory]);

  const addItem = (item: BasicCatalogueLineModelService) => {
    setShoppingList((prevList) => {
      const newData = [...prevList];
      const findIndex = newData.findIndex((x) => x.catalogueLineId === item.catalogueLineId);
      if (findIndex !== -1) {
        newData[findIndex] = { ...item, allowsUrgent };
      } else {
        newData.push({ ...item, allowsUrgent });
      }
      return newData;
    });
  };

  const removeItem = (catalogueLineId: string) => {
    setShoppingList((prevList) => prevList.filter((shoppingItem) => shoppingItem.catalogueLineId !== catalogueLineId));
  };
  const updateOrderData = (article: BasicCatalogueLineModelService) => {
    if (article.quantity === 0) {
      removeItem(article.catalogueLineId);
    } else {
      addItem(article);
    }
  };

  const allowUrgentArticles = isUrgentOrder && shoppingList.some((item) => !item.allowsUrgent);
  const enableAddButton = shoppingList.filter((list) => list.quantity > 0 && !allowUrgentArticles);

  const filterTypes = [
    {
      id: 'reference',
      text: 'reference',
    },
    {
      id: 'referenceDesc',
      text: 'referenceDesc',
    },
    {
      id: 'color',
      text: 'color',
    },
    {
      id: 'colorDesc',
      text: 'colorDesc',
    },
  ];

  return (
    <Dialog open={isOpen} className="hmy-modal-order" fullWidth maxWidth="xl" fullScreen={matches}>
      <Box className="hmy-modal-order-container">
        <Box className="hmy-modal-order-filters">
          {data && searchCatalogCategory && searchCatalog && (
            <>
              <AutocompleteCatalogue
                value={searchCatalogCategory}
                elements={catalogueCategories.map((category: string) => ({
                  id: category,
                  text: category === CATEGORY_NONE ? intl.formatMessage({ id: category }) : category,
                }))}
                setValue={setSearchCatalogCategory}
                isFormattedValue={false}
                defaultValue=""
                title="catalogueCategory"
              />
              <AutocompleteCatalogue
                value={searchCatalog}
                elements={
                  filteredCatalogues.map((catalogue: BasicCatalagueModelService) => ({
                    id: catalogue.catalogueId,
                    text: catalogue.name,
                    urgent: catalogue.allowsUrgent,
                  })) as any
                }
                setValue={setSearchCatalog}
                isFormattedValue={false}
                defaultValue=""
                title="catalogue"
              />
            </>
          )}
          <HmySelectable
            value={searchType}
            elements={filterTypes}
            setValue={setSearchType}
            isFormattedValue={true}
            defaultValue=""
            title="filterType"
          />
          <input value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <div className="hmy-modal-order-filters-search" onClick={() => {}}>
            <SearchOutlinedIcon style={{ fontSize: '22px' }} />
          </div>
        </Box>
        <Box className="hmy-modal-order-lines-container">
          {catalogueData &&
            catalogueData.map((catalogueLine: BasicCatalogueLineModelService) => {
              if (searchText && searchType) {
                const type = searchType as keyof BasicCatalogueLineModelService;
                if (
                  catalogueLine[type] &&
                  typeof catalogueLine[type] === 'string' &&
                  !catalogueLine[type].toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                ) {
                  return null;
                }
              }
              const shoppingIndex = shoppingList.findIndex(
                (shoppingItem) => shoppingItem.catalogueLineId === catalogueLine.catalogueLineId
              );
              const line = shoppingIndex === -1 ? catalogueLine : shoppingList[shoppingIndex];
              return (
                <Box
                  key={catalogueLine.catalogueLineId}
                  className={line.quantity > 0 ? 'catalogue-line catalogue-line-active' : 'catalogue-line'}
                >
                  {line.articleFiles[0].isImage ? (
                    <ImageArticle thumbnail={line.articleFiles[0].urlThumbnail} imageId={line.articleFiles[0].fileId} />
                  ) : (
                    <PdfArticle thumbnail={line.articleFiles[0].urlThumbnail} article={line.articleFiles[0]} />
                  )}
                  <Box className="catalogue-line-description">
                    <div className="catalogue-line-description-item">{line.referenceDesc}</div>
                    <div>{line.colorDesc}</div>
                    <div>{line.reference}</div>
                    <div>{line.color}</div>
                    {line.minQuantity && line.minQuantity !== 0 ? (
                      <div>
                        <FormattedMessage id="msg.minQuantity" values={{ value: line.minQuantity }} />
                      </div>
                    ) : null}
                  </Box>
                  <Box className="catalogue-line-price">
                    <div>{formatPrice(line.salePrice)}</div>
                    <Box className="catalogue-line-price-controls">
                      <Box
                        onClick={() =>
                          updateOrderData({
                            ...line,
                            quantity:
                              typeof line.quantity !== 'undefined' && line.quantity > line.minQuantity
                                ? line.quantity - 1
                                : 0,
                          })
                        }
                      >
                        <RemoveIcon style={{ fontSize: '16px' }} />
                      </Box>
                      <TextField
                        value={line.quantity}
                        variant="filled"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ inputProps: { min: line.minQuantity, step: '1' } }}
                        size="small"
                        style={{ width: '60px' }}
                        sx={{ '& input': { paddingTop: '4px', height: '20px', fontSize: '16px', textAlign: 'end' } }}
                        onChange={(e) => {
                          updateOrderData({
                            ...line,
                            quantity: isNaN(Number(e.target.value))
                              ? line.quantity
                              : Number(e.target.value) >= line.minQuantity
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                      />

                      <Box
                        onClick={() =>
                          updateOrderData({
                            ...line,
                            quantity:
                              typeof line.quantity !== 'undefined'
                                ? line.quantity + (line.quantity >= line.minQuantity ? 1 : line.minQuantity)
                                : 0,
                          })
                        }
                      >
                        <AddIcon style={{ fontSize: '16px' }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
        <Box className="hmy-modal-order-controls">
          {isUrgentOrder && allowUrgentArticles && (
            <div className="catalogue-urgent-error">
              <FormattedMessage id="errorCatalogueUrgent" />
            </div>
          )}
          <HmyBtn title="btn.comeBack" width={250} fullwith={false} color="secondary" handleClick={close} />
          <HmyBtn
            title="btn.add"
            width={250}
            fullwith={false}
            color="primary"
            filled
            disabled={enableAddButton.length === 0}
            handleClick={() => {
              addItems(shoppingList);
              close();
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default HmyModalOrder;
