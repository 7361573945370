import CommentIcon from '@mui/icons-material/Comment';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { HmyTableRow } from 'src/components';
import { SaleOrderIncidenceModel } from 'src/models/orders';
import { IncidenceStatusText } from '../incidence-status-text';
import './hmy-table-row-incidences.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteIncidence } from 'src/hooks/use-incidence.hook';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { ErrorMessage, SuccessMessage } from 'src/models';

type HmyTableRowIncidencesProps = {
  isFirst: boolean;
  isLast: boolean;
  incidence: SaleOrderIncidenceModel;
  isDeletable: boolean;
};

const HmyTableRowIncidences: FC<HmyTableRowIncidencesProps> = ({ isFirst, isLast, incidence, isDeletable }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutationDeleteIncidence } = useDeleteIncidence(incidence.incidenceId, incidence.saleOrderId);
  const [popConfirmDeletion, setPopConfirmDeletion] = useState(false);

  const handleClickIncidence = () => {
    navigate(`/incidence/${incidence.incidenceId}`);
  };

  function handleDelete() {
    mutationDeleteIncidence.mutate(incidence.incidenceId, {
      onSuccess: () => {
        dispatch(setMessage(SuccessMessage('success.DeletedIncidence', true)));
        setPopConfirmDeletion(false);
      },
      onError: () => {
        dispatch(setMessage(ErrorMessage('error.DeletedIncidence', true)));
      },
    });
  }
  const handleCancelDeletion = () => {
    setPopConfirmDeletion(false);
  };
  return (
    <>
      <HmyTableRow isFirst={isFirst} isLast={isLast}>
        <Box
          className="incidences-table-row-element"
          style={{
            width: isDeletable ? '26%' : '30%',
            justifyContent: 'start',
            alignItems: 'center',
            paddingLeft: '10px',
            cursor: 'pointer',
          }}
          onClick={handleClickIncidence}
        >
          <Box style={{ width: '40px', justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
            {incidence.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
          </Box>
          {incidence.number}
        </Box>
        <Box
          className="incidences-table-row-element"
          style={{ width: isDeletable ? '26%' : '30%', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handleClickIncidence}
        >
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={incidence.date} />
        </Box>

        <Box
          className="incidences-table-row-element"
          style={{ width: isDeletable ? '36%' : '40%', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handleClickIncidence}
        >
          <IncidenceStatusText statusNumber={incidence.statusNumber} />
        </Box>
        {isDeletable ? (
          <Box className="incidences-table-row-element" style={{ width: '12%', justifyContent: 'center' }}>
            <DeleteIcon className="delete-icon" fontSize="small" onClick={() => setPopConfirmDeletion(true)} />
          </Box>
        ) : null}
      </HmyTableRow>
      <Dialog
        open={popConfirmDeletion}
        onClose={handleCancelDeletion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'incidenceDelete' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: 'incidenceDeleteDetail' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelDeletion()}>{intl.formatMessage({ id: 'btn.cancel' })}</Button>
          <Button onClick={() => handleDelete()}>{intl.formatMessage({ id: 'btn.ok' })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HmyTableRowIncidences;
