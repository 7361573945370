import CommentIcon from '@mui/icons-material/Comment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Badge, Box, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FaTruckFast } from 'react-icons/fa6';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { HmyCheckBox, HmyTableRow } from 'src/components';
import { HmyStatusText } from 'src/components/hmy-status-text';
import { useFetchAndLoad } from 'src/hooks';
import { STATUS_ORDER, useOrderStates } from 'src/hooks/use-order-states.hook';
import { CurrentUser } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import PdfOrder from 'src/pages/order/pdf-order';
import { orderServices } from 'src/services/orders';
import { IsCustomer, adjustString } from 'src/utilities';
import { downloadExcel } from '../download-excel-helpers';
import IncidenceButton from '../incidence-button';
import './hmy-table-row-order.scss';

type HmyTableRowOrderProps = {
  isFirst: boolean;
  isLast: boolean;
  order: BasicOrder;
  currentUser: CurrentUser;
  selectedOrders: BasicOrder[];
  handleClickOrder: (projectId: string, orderId: string) => void;
  handleCheckRow: (saleOrderId: string) => void;
};

const HmyTableRowOrder: FC<HmyTableRowOrderProps> = ({
  isFirst,
  isLast,
  order,
  currentUser,
  selectedOrders,
  handleClickOrder,
  handleCheckRow,
}) => {
  const { callEndpoint } = useFetchAndLoad();
  const [printOrderId, setPrintOrderId] = useState<string>('');
  const [printOrderLoading, setPrintOrderLoading] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>();
  const matches = useMediaQuery('(max-width: 1024px)');

  const { getStateNumber } = useOrderStates();

  const handleDownloadExcel = () => {
    callEndpoint(orderServices.postOrderExcel({ saleOrderId: order.saleOrderId })).then((response) => {
      response && downloadExcel(response.data, `${order.projectName}-${order.number}`);
    });
  };

  const handleClosePdf = () => {
    setPrintOrderId('');
    setPrintOrderLoading(false);
  };

  const handlePrint = () => {
    setCurrentDate(new Date());
    setPrintOrderId(order.saleOrderId);
    setPrintOrderLoading(true);
  };

  return (
    <HmyTableRow isFirst={isFirst} isLast={isLast}>
      <Box
        className="import-projects-table-row-element"
        style={{ width: '5%', justifyContent: 'flex-center', cursor: 'pointer' }}
        onClick={() => handleCheckRow(order.saleOrderId)}
      >
        <HmyCheckBox
          color="primary"
          isChecked={selectedOrders.findIndex((x) => x.saleOrderId === order.saleOrderId) > -1}
        />
      </Box>
      <Box
        className="grouping-file-table-row-element orders-table-row-element"
        style={{
          width: '15%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        <Box
          style={{
            justifyContent: 'start',
            alignItems: 'center',
            display: 'flex',
            gap: '10px',
            position: 'absolute',
            left: 0,
          }}
        >
          {order.urgent && <FaTruckFast style={{ fontSize: '20px' }} />}
          {order.openIncidences > 0 && (
            <>
              <Badge className="orders-incidence-badge" badgeContent={order.openIncidences}>
                <FeedbackIcon style={{ width: '20px', color: 'grey' }} />
              </Badge>
              {order.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
            </>
          )}
        </Box>
        {order.number}
      </Box>
      <Box
        className="orders-table-row-element"
        style={{ width: '10%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        {order.linesQuantity}
      </Box>
      <Box
        className="orders-table-row-element"
        style={{ width: '20%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        {adjustString(order.projectName, 35)}
      </Box>
      <Box
        className="orders-table-row-element"
        style={{ width: '10%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={order.date} />
      </Box>
      <Box
        className="orders-table-row-element"
        style={{ width: '10%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        <HmyStatusText id={order.statusId} />
      </Box>
      <Box
        className="orders-table-row-element"
        style={{ width: '15%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => handleClickOrder(order.projectId, order.saleOrderId)}
      >
        {order.createdByEmail}
      </Box>
      <Box
        className="orders-table-row-element"
        style={{
          width: '15%',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '6px',
          ...(matches && { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }),
        }}
      >
        {getStateNumber(order.statusId) === STATUS_ORDER.DELIVERED ||
        getStateNumber(order.statusId) === STATUS_ORDER.SENT ? (
          <IncidenceButton orderId={order.saleOrderId} />
        ) : (
          <div></div>
        )}
        <Box className="orders-button" onClick={handlePrint}>
          {printOrderLoading ? (
            <>
              {!matches && <FormattedMessage id="loading" />}
              ...
            </>
          ) : (
            <>
              <InsertDriveFileOutlinedIcon className="orders-button-icon red" />
              {!matches && <FormattedMessage id="PDF" />}
            </>
          )}
        </Box>
        {!IsCustomer(currentUser) && (
          <Box className="orders-button" onClick={handleDownloadExcel}>
            <TableViewOutlinedIcon className="orders-button-icon green" />
            {!matches && <FormattedMessage id="excel" />}
          </Box>
        )}
      </Box>
      {printOrderId && currentDate && (
        <PdfOrder
          orderId={order.saleOrderId}
          title={`${order.projectName}-${order.number}`}
          close={handleClosePdf}
          date={currentDate}
        />
      )}
    </HmyTableRow>
  );
};

export default HmyTableRowOrder;
