import { SubRoute } from 'src/models';

export const ROUTE_LANDING = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGN_ON = '/sign-on/:commercial';
export const ROUTE_INSPIRATION = '/inspiration';
export const ROUTE_HOME = '/home';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_ORDERS = '/orders';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_IMPORT_PROJECTS = '/projects/import';
export const ROUTE_PROJECT = '/project/:projectid';
export const ROUTE_ORDER = '/order/:orderid';
export const ROUTE_NEW_ORDER = '/new-order/:projectid';
export const ROUTE_USER_GROUPINGS = '/user-groupings';
export const ROUTE_USER_GROUPING = '/user-grouping/:groupingid';
export const ROUTE_USER_GROUPINGS_NEW = '/user-groupings/new';
export const ROUTE_NOT_FOUND = '*';
export const ROUTE_MANAGEMENT = '/management';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_ACCESS_DENIED = '/access-denied';
export const ROUTE_SERVER_DOWN = '/serverdown';
export const ROUTE_RECOVER_PASS = '/staticrecoverpass/:token';
export const ROUTE_REGISTER_USER = '/staticregisteruser/:token';
export const ROUTE_INCIDENCE = '/incidence/:incidenceid';

/**Subrutas projectos */
export const SUBROUTE_INBOX = 'inbox';
export const SUBROUTE_LANDMARKS = 'documents';
export const SUBROUTE_IMAGES = 'images';
export const SUBROUTE_VSTORE = 'drawing';
export const SUBROUTE_SETTINGS_PROJECT = 'settings';
export const SUBROUTE_ORDERS = 'orders';

export type projectSubRoutes =
  | typeof SUBROUTE_INBOX
  | typeof SUBROUTE_LANDMARKS
  | typeof SUBROUTE_IMAGES
  | typeof SUBROUTE_VSTORE
  | typeof SUBROUTE_SETTINGS_PROJECT
  | typeof SUBROUTE_ORDERS;

/**Subrutas Management */
export const SUBROUTE_USERS = 'users';
export const SUBROUTE_USER = '/:userid';
export const SUBROUTE_USER_NEW = 'new';
export const SUBROUTE_STATISTICS = 'statistics';
export const SUBROUTE_REPORTS = 'reports';
export const SUBROUTE_COMPANIES = 'companies';

export type managementSubRoutes =
  | typeof SUBROUTE_USERS
  | typeof SUBROUTE_USER
  | typeof SUBROUTE_STATISTICS
  | typeof SUBROUTE_COMPANIES
  | typeof SUBROUTE_REPORTS;

/**Subrutas Grouping */
export const SUBROUTE_GROUPING_FILES = 'files';
export const SUBROUTE_GROUPING_INFORMATION = 'information';

export type groupingSubRoutes = typeof SUBROUTE_GROUPING_FILES | typeof SUBROUTE_GROUPING_INFORMATION;

export const mainRoutes: SubRoute[] = [
  {
    name: ROUTE_INSPIRATION.slice(1),
    link: ROUTE_INSPIRATION,
  },
  {
    name: ROUTE_PROJECTS.slice(1),
    link: ROUTE_PROJECTS,
  },
  {
    name: ROUTE_ORDERS.slice(1),
    link: ROUTE_ORDERS,
  },
  {
    name: ROUTE_USER_GROUPINGS.slice(1),
    link: ROUTE_USER_GROUPINGS,
  },
  {
    name: ROUTE_REPORTS.slice(1),
    link: ROUTE_REPORTS,
  },
  {
    name: ROUTE_MANAGEMENT.slice(1),
    link: ROUTE_MANAGEMENT,
  },
  {
    name: ROUTE_PROFILE.slice(1),
    link: ROUTE_PROFILE,
  },
];

export const loginRoutes: SubRoute[] = [
  {
    name: ROUTE_INSPIRATION.slice(1),
    link: ROUTE_LANDING,
  },
  {
    name: ROUTE_LOGIN.slice(1),
    link: ROUTE_LOGIN,
  },
];
