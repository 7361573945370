import CommentIcon from '@mui/icons-material/Comment';
import { FC, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { SaleOrderIncidenceModel } from 'src/models/orders';
import { IncidenceStatusText } from '../incidence-status-text';
import './hmy-card-incidences.scss';
import { useNavigate } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteIncidence } from 'src/hooks/use-incidence.hook';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type HmyCardIncidencesProps = {
  incidence: SaleOrderIncidenceModel;
  isDeletable: boolean;
};

const HmyCardIncidences: FC<HmyCardIncidencesProps> = ({ incidence, isDeletable }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutationDeleteIncidence } = useDeleteIncidence(incidence.incidenceId, incidence.saleOrderId);
  const [popConfirmDeletion, setPopConfirmDeletion] = useState(false);

  const handleClickIncidence = () => {
    navigate(`/incidence/${incidence.incidenceId}`);
  };

  function handleDelete() {
    mutationDeleteIncidence.mutate(incidence.incidenceId, {
      onSuccess: () => {
        dispatch(setMessage(SuccessMessage('success.DeletedIncidence', true)));
        setPopConfirmDeletion(false);
      },
      onError: () => {
        dispatch(setMessage(ErrorMessage('error.DeletedIncidence', true)));
      },
    });
  }
  const handleCancelDeletion = () => {
    setPopConfirmDeletion(false);
  };

  return (
    <>
      <Box className="card-incidences-container">
        <Box className="card-incidences-item">
          <Box
            style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}
            onClick={handleClickIncidence}
          >
            <Box style={{ width: '40px', justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
              {incidence.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
            </Box>
            {incidence.number}
          </Box>
          <Box onClick={handleClickIncidence}>
            <IncidenceStatusText statusNumber={incidence.statusNumber} />
          </Box>
          <Box onClick={handleClickIncidence}>
            <FormattedDate year="numeric" month="2-digit" day="2-digit" value={incidence.date} />
          </Box>
          {isDeletable ? (
            <Box>
              <DeleteIcon className="delete-icon" fontSize="small" onClick={() => setPopConfirmDeletion(true)} />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Dialog
        open={popConfirmDeletion}
        onClose={handleCancelDeletion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'incidenceDelete' })}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: 'incidenceDeleteDetail' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelDeletion()}>{intl.formatMessage({ id: 'btn.cancel' })}</Button>
          <Button onClick={() => handleDelete()}>{intl.formatMessage({ id: 'btn.ok' })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HmyCardIncidences;
