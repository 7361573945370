import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { completeProjectAdapter } from 'src/adapters';
import { HmyBtn, LoadComponent } from 'src/components';
import { HmyBtnHelp } from 'src/components/hmy-btn-help';
import { useFetchAndLoad } from 'src/hooks';
import { useOrder } from 'src/hooks/use-order.hook';
import { CompleteProject, ErrorMessage, SuccessMessage } from 'src/models';
import { BasicOrder, BasicOrderLines } from 'src/models/orders';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { projectServices } from 'src/services';
import { BasicCatalogueLineModelService } from 'src/services/catalogue';
import HmyModalOrder from './hmy-modal-order';
import OrderArticles from './order-articles';
import OrderHeader from './order-header';
import OrderTotals from './order-totals';
import './order.scss';
import { STATUS_ORDER, useOrderStates } from 'src/hooks/use-order-states.hook';
import IncidenceModal from './incidences/incidence-modal';
import OrderIncidences from './incidences/order-incidences';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { IsAdmin, IsCustomer } from 'src/utilities';
import { calculeOrderPrice } from './calcule-order-price.helpers';
import AddDocuments from './add-documents';
import { CreateInboxBlobDto } from 'src/dtos';

const Order: FC = () => {
  const [orderData, setOrderData] = useState<BasicOrder>();
  const [editable, setEditable] = useState<boolean>(false);
  const [editableStatus, setEditableStatus] = useState<boolean>(false);
  const [project, setProject] = useState<CompleteProject>();
  const [currentState, setCurrentState] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [orderEdited, setOrderEdited] = useState<boolean>(false);
  const [openIncidence, setOpenIncidence] = useState<boolean>(false);
  // Documents
  const [files, setFiles] = useState<CreateInboxBlobDto[]>([]);

  const currentUser = useSelector(currentUserSelector);
  const { callEndpoint } = useFetchAndLoad();

  const { orderid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getStateNumber, getStateId } = useOrderStates();
  const matches = useMediaQuery('(max-width: 768px)');
  const { data, isLoading, mutationUpdateOrder } = useOrder(orderid);
  const getProject = async (projectid: string) => await callEndpoint(projectServices.getProject(projectid));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addCatalogItem = (items: BasicOrderLines[] | BasicCatalogueLineModelService[]) => {
    setOrderData((prevData) => {
      if (prevData && prevData.lines) {
        let newData = [...prevData.lines];
        items.forEach((item) => {
          const newItem = item as BasicOrderLines;

          const findIndex = newData.findIndex((x) => x.reference === newItem.reference);
          if (findIndex !== -1) {
            newData[findIndex] = { ...newItem, quantity: newData[findIndex].quantity + newItem.quantity };
          } else {
            newData.push(newItem);
          }
        });

        return { ...prevData, lines: newData };
      }
    });
    setOrderEdited(true);
  };

  const addItems = (articles: BasicOrderLines[] | BasicCatalogueLineModelService[]) => {
    addCatalogItem(articles);
    setOrderEdited(true);
  };

  const setFilesToOrder = (files: CreateInboxBlobDto[]) => {
    setFiles(files);
    if (orderData) {
      setOrderData({ ...orderData, blobs: files.map((file) => ({ fileId: null, ...file })) });
    }
  };

  const changeUrgent = (newUrgent: boolean) => {
    if (orderData !== undefined) {
      setOrderData({ ...orderData, urgent: newUrgent });
      setOrderEdited(true);
    }
  };

  useEffect(() => {
    if (!orderData && data) {
      setOrderData(data);
      setCurrentState(data.statusId);
      getProject(data.projectId).then((response) => {
        if (response.status === 200) {
          setProject(completeProjectAdapter(response.data));
        }
      });
    } else if (orderData && data) {
      setOrderData({ ...orderData, incidences: data.incidences, blobs: data.blobs });
    }
    if (data) {
      setEditable(
        getStateNumber(data.statusId) === STATUS_ORDER.REGISTERED ||
          (getStateNumber(data.statusId) === STATUS_ORDER.IN_PROGRESS && editableStatus)
      );
    }
  }, [data, editableStatus]);

  // Set order editable to select profiles (Commercial, ProjectManager and Collaborator)
  useEffect(() => {
    if (currentUser && project) {
      const projectUsersEditable = project.users.filter(
        (user) =>
          user.profileName.toLowerCase() === 'commercial' ||
          user.profileName.toLowerCase() === 'projectmanager' ||
          user.profileName.toLowerCase() === 'collaborator'
      );
      setEditableStatus(
        projectUsersEditable.findIndex((user) => user.id === currentUser.id) !== -1 ||
          IsAdmin(currentUser) ||
          currentUser.hmy
      );
    }
  }, [currentUser, project]);
  const transformedOrderData = useMemo(() => {
    if (orderData) {
      const { statusId, statusNumber, statusName, shippingPrice, subTotal, total, ...restOrderData } = orderData;
      return {
        ...restOrderData,
        lines: orderData?.lines
          ?.sort((a, b) => a.catalogueLineId.localeCompare(b.catalogueLineId))
          .map(({ catalogueLineId, quantity }: any) => ({ catalogueLineId, quantity })),
      };
    }
  }, [orderData]);

  const transformedData = useMemo(() => {
    if (data) {
      const { statusId, statusNumber, statusName, shippingPrice, subTotal, total, ...restData } = data;
      return {
        ...restData,
        lines: data?.lines
          ?.sort((a: any, b: any) => a.catalogueLineId.localeCompare(b.catalogueLineId))
          .map(({ catalogueLineId, quantity }: any) => ({ catalogueLineId, quantity })),
      };
    }
  }, [data]);

  const isDisabledByMinOrderSale =
    project && orderData && project.minOrderAmount
      ? project.minOrderAmount > calculeOrderPrice(orderData).subTotal
      : false;

  const handleSentOrder = () => {
    if (orderData) {
      mutationUpdateOrder.mutate(
        { ...orderData, statusId: currentState },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CreateOrder', true)));
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CreateOrder', true)));
          },
        }
      );
    }
  };

  const handleCancelOrder = () => {
    if (data) {
      mutationUpdateOrder.mutate(
        { ...data, statusId: getStateId(STATUS_ORDER.CANCELLED) },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CancelOrder', true)));
            navigate(-1);
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CancelOrder', true)));
          },
        }
      );
    }
  };

  const handleUpdateOrderData = (article: BasicOrderLines | BasicCatalogueLineModelService) => {
    const item = article as BasicOrderLines;
    if (orderData) {
      const newData = [...(orderData.lines || [])];
      const findIndex = newData.findIndex((x) => x.saleOrderLineId === item.saleOrderLineId);
      newData[findIndex] = item;
      setOrderData({ ...orderData, lines: newData });
      setOrderEdited(true);
    }
  };

  const handleRemoveArticle = (reference: string, color: string) => {
    if (orderData && orderData.lines) {
      setOrderData({
        ...orderData,
        lines: orderData.lines.filter((x) => !(x.reference === reference && x.color === color)),
      });
      setOrderEdited(true);
    }
  };

  if (isLoading) {
    return <LoadComponent />;
  }

  if (!orderData) {
    return <></>;
  }

  function countTotalLinesQuantity() {
    let count = 0;
    if (transformedOrderData && transformedOrderData.lines && transformedOrderData.lines.length > 0) {
      transformedOrderData.lines.forEach((line) => (count += line.quantity));
    }
    return count;
  }

  return (
    <Box className="order-container">
      <Box style={{ display: 'flex', justifyContent: 'space-between', columnGap: '20px', width: '85%' }}>
        <Box className="order-title" onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize="inherit" />
          <Typography className="order-title-text">
            {matches ? null : <FormattedMessage id="order" />}
            <span className="order-title-text-order">{orderData.number}</span>
          </Typography>
        </Box>
        {editable && (
          <HmyBtn title="catalogue" width={300} fullwith={false} color="secondary" filled handleClick={handleOpen} />
        )}
        {(getStateNumber(currentState) === STATUS_ORDER.DELIVERED ||
          getStateNumber(currentState) === STATUS_ORDER.SENT) && (
          <HmyBtn
            title="btn.addIncidence"
            width={200}
            filled
            fullwith={false}
            color="primary"
            handleClick={() => setOpenIncidence(true)}
          />
        )}
        {openIncidence && (
          <IncidenceModal
            orderId={orderData.saleOrderId}
            orderData={orderData}
            closeModal={() => setOpenIncidence(false)}
          />
        )}
        <HmyBtnHelp projectId={orderData.projectId} />
      </Box>
      {open && (
        <HmyModalOrder
          isOpen={open}
          close={handleClose}
          addItems={addItems}
          projectid={data?.projectId ?? ''}
          isUrgentOrder={data.urgent}
        />
      )}
      <Box className="order-main">
        <OrderHeader
          orderData={orderData}
          editableStatus={editableStatus}
          editable={editable}
          currentState={currentState}
          setCurrentState={setCurrentState}
          orderEdited={orderEdited}
          changeUrgent={(urgent) => changeUrgent(urgent)}
        />
        <Typography className="order-title-text">
          <FormattedMessage id="orderObservations" />
        </Typography>
        {orderData.comments && <HmyInputTextarea value={orderData.comments} setValue={() => {}} minRows={1} readOnly />}
        <OrderTotals orderData={orderData} orderEdited={orderEdited} urgent={data.urgent} />
        <OrderArticles
          orderData={orderData}
          editable={editable}
          updateOrderData={handleUpdateOrderData}
          handleRemoveArticle={handleRemoveArticle}
        />
        {project && <AddDocuments project={project} files={files} setFiles={setFilesToOrder} orderData={orderData} />}
        {orderData.incidences.length > 0 && <OrderIncidences orderData={orderData} />}
        {(editable || editableStatus) && (
          <>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: '20px',
                width: '100%',
                paddingBlockEnd: '20px',
              }}
            >
              {IsCustomer(currentUser) && editable && (
                <HmyBtn
                  title="btn.cancel"
                  width={300}
                  fullwith={false}
                  color="secondary"
                  handleClick={handleCancelOrder}
                />
              )}
              <HmyBtn
                title="modifyOrder"
                width={300}
                fullwith={false}
                color="primary"
                filled
                handleClick={handleSentOrder}
                disabled={
                  JSON.stringify(transformedOrderData) === JSON.stringify(transformedData) ||
                  transformedOrderData?.lines?.length === 0 ||
                  countTotalLinesQuantity() === 0 ||
                  isDisabledByMinOrderSale
                }
              />
            </Box>
            {project && project.minOrderAmount > 0 && isDisabledByMinOrderSale && (
              <Box style={{ textAlign: 'right', fontSize: '0.8em', alignSelf: 'end', padding: '0 0 20px' }}>
                <FormattedMessage id="msg.minOrderAmount" values={{ value: project.minOrderAmount }} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Order;
