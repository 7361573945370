import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HmyBtn } from 'src/components';
import { HmySelectableAutocomplete } from 'src/components/hmy-selectable-autocomplete';
import { useFetchAndLoad } from 'src/hooks';
import { CompleteProject, GeneralCollection } from 'src/models';
import { projectServices } from 'src/services';
import { BasicShoppingOrder } from 'src/services/catalogue';
import '../order.scss';
import NewOrderHeaderProject from './new-order-header-project';

type NewOrderHeaderProps = {
  orderData: BasicShoppingOrder;
  projectData: CompleteProject;
  setPhoneNumber: (phoneNumber: string) => void;
  customerData: { id: String; email: string } | null;
  additionalProjects: string[];
  setAdditionalProjects: (projectsId: string[]) => void;
  setSelectedCatalogues: (data: string[]) => void;
};

const NewOrderHeader: FC<NewOrderHeaderProps> = ({
  orderData,
  projectData,
  setPhoneNumber,
  customerData,
  additionalProjects,
  setAdditionalProjects,
  setSelectedCatalogues,
}) => {
  const [projects, setProjects] = useState<CompleteProject[]>([]);
  const [openProjectSelect, setOpenProjectSelect] = useState<boolean>(false);
  const [selectProject, setSelectProject] = useState<string>('');
  const { callEndpoint } = useFetchAndLoad();

  const getListProjects = async () =>
    await callEndpoint(
      projectServices.getFilterProjects({ filter: '', showClosedProjects: false, showDeletedProjects: false })
    );
  useEffect(() => {
    setSelectedCatalogues(projectData.catalogueIds);
    getListProjects()
      .then((response: { data: GeneralCollection<CompleteProject> }) =>
        setProjects(getAvailableProjects(additionalProjects, response.data.items))
      )
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectProject) {
      setAdditionalProjects([...additionalProjects, selectProject]);
      setProjects(getAvailableProjects([...additionalProjects, selectProject], projects));
      setSelectProject('');
    }
  }, [selectProject]);

  const getProjectById = (projectId: string) => projects.find((project) => project.projectId === projectId);

  const getAvailableProjects = (selectedProjects: string[], allProjects: CompleteProject[]) => {
    // Get the intersection of all catalogueIds from the selected projects
    const commonCatalogueIds = selectedProjects.reduce((commonIds, projectId) => {
      const project = getProjectById(projectId);
      return commonIds.filter((id: string) => project?.catalogueIds.includes(id));
    }, projectData.catalogueIds);
    setSelectedCatalogues(commonCatalogueIds);

    // Filter the projects that have at least one catalogueId in common
    return allProjects.map((project) => {
      if (
        project.catalogueIds.some((id) => commonCatalogueIds.includes(id)) &&
        !selectedProjects.includes(project.projectId) &&
        project.projectId !== projectData.projectId
      ) {
        return { ...project, show: true };
      }
      return { ...project, show: false };
    });
  };

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="generalInformation" />
      </Typography>
      <NewOrderHeaderProject
        orderData={orderData}
        customerData={customerData}
        isPrincipalProject
        projectData={projectData}
        setPhoneNumber={setPhoneNumber}
      />

      {additionalProjects.map((projectId) => {
        const selectedProject = getProjectById(projectId);
        if (selectedProject) {
          return (
            <NewOrderHeaderProject
              orderData={{ ...orderData, projectId: selectedProject.projectId }}
              customerData={customerData}
              isPrincipalProject={false}
              projectData={selectedProject}
              setPhoneNumber={setPhoneNumber}
              key={selectedProject.projectId}
            />
          );
        }
      })}

      {projects.filter((project: any) => project.show).length > 0 && (
        <Box className="order-title-add-project">
          {openProjectSelect && (
            <HmySelectableAutocomplete
              value={selectProject}
              elements={projects
                .filter((project: any) => project.show)
                .map((project) => ({
                  id: project.projectId,
                  text: project.projectName ?? project.description,
                }))}
              setValue={setSelectProject}
              isFormattedValue={false}
              defaultValue=""
              title="project"
            />
          )}

          <HmyBtn
            title="addNewProject"
            width={300}
            fullwith={false}
            color="secondary"
            handleClick={() => setOpenProjectSelect(true)}
            filled
            disabled={orderData.lines.length > 0}
          />
        </Box>
      )}
    </>
  );
};

export default NewOrderHeader;
