import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { modalDocumentAdapter } from 'src/adapters';
import { HmyModalFileViewer, HmyPdfViewer } from 'src/components';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices } from 'src/services';

type ViewDocumentProps = {
  documentId: string;
  description: string;
};

const ViewDocument: FC<ViewDocumentProps> = ({ documentId, description }) => {
  const matches = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const [document, setDocument] = useState<any>();
  const { callEndpoint } = useFetchAndLoad();
  const getDocument = async (documentId: string) => await callEndpoint(blobServices.getBlob(documentId));

  useEffect(() => {
    if (documentId && isOpen) {
      getDocument(documentId).then((response) => setDocument(modalDocumentAdapter(response.data)));
    }
  }, [documentId, isOpen]);

  const handleCloseModalViewer = () => {
    setIsOpen(false);
    setDocument(null);
  };
  return (
    <>
      <div
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', columnGap: '4px' }}
        onClick={() => setIsOpen(true)}
      >
        <TextSnippetOutlinedIcon /> {description}
      </div>
      {isOpen && document && (
        <HmyModalFileViewer
          isOpen={isOpen}
          canEdit={false}
          file={document}
          handleClose={handleCloseModalViewer}
          hanldeClickVisible={() => setIsOpen(true)}
        >
          {document.isImage ? (
            <img src={document.url} style={{ height: 'calc(100vh - 100px)' }} />
          ) : (
            <HmyPdfViewer
              base64String={document.url}
              pdfName={document.description ?? document.name}
              zoomManagement={true}
              rotationManagement={!matches}
              closeManagement={false}
              navBarHeight={50}
              handleClose={handleCloseModalViewer}
            />
          )}
        </HmyModalFileViewer>
      )}
    </>
  );
};

export default ViewDocument;
