import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { completeProjectAdapter, documentPropsAdapter, modalDocumentAdapter } from 'src/adapters';
import { HmyBtn, HmyImageViewer, HmyInboxFileZone, HmyModalFileViewer, LoadComponent } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { useUpdateIncidenceStatus } from 'src/hooks/use-incidence-states.hook';
import { useAddFilesIncidence, useCreateComment, useIncidence } from 'src/hooks/use-incidence.hook';
import { CompleteProject, DocumentProps, ErrorMessage, HmyModalDocumentBase, SuccessMessage } from 'src/models';
import { currentUserSelector, setMessage } from 'src/redux/states';
import { DocumentPropsModelService, MAX_SIZE, blobServices, projectServices } from 'src/services';
import { incidenceServices } from 'src/services/incidence';
import { IsAdmin } from 'src/utilities';
import { IncidenceNewFileForm } from '../order/incidences/incidence-file-add/incidence-file-add-form';
import { IncidenceStatusText } from '../order/incidences/order-incidences/incidence-status-text';
import IncidenceComment from './incidence-comment';
import IncidenceStatus from './incidence-status';
import './incidence.scss';
import { CreateInboxBlobDto } from 'src/dtos';
import { getBase64 } from 'src/utilities/get-base64';

type IncidenceFileAddObject = {
  file: File;
  name: string;
  date: Date;
};

const Incidence: FC = () => {
  const [newComment, setNewComment] = useState<string>('');
  const [isEditable, setIsEditable] = useState<boolean>();
  const [project, setProject] = useState<CompleteProject>();
  const [selectedDocument, setSelectedDocument] = useState<HmyModalDocumentBase | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [documentProperties, setDocumentProperties] = useState<DocumentProps>();
  const [values, setValues] = useState<IncidenceNewFileForm[]>([]);

  const currentUser = useSelector(currentUserSelector);

  const { incidenceid } = useParams();
  const { mutationCreateComment } = useCreateComment();
  const { mutationUpdateIncidenceStatus } = useUpdateIncidenceStatus(incidenceid);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { callEndpoint } = useFetchAndLoad();
  const matches = useMediaQuery('(max-width: 768px)');

  const { data: incidence, isLoading } = useIncidence(incidenceid ?? '');
  const { mutationAddImage } = useAddFilesIncidence(incidenceid ?? '');
  const getProject = async (projectid: string) => await callEndpoint(projectServices.getProject(projectid));
  const getDocument = async (fileId: string) => await callEndpoint(blobServices.getBlob(fileId));
  const deleteDocument = async (fileId: string) => await callEndpoint(incidenceServices.deleteFile(fileId));
  const getDocumentProps = async () => await callEndpoint(incidenceServices.getDocumentProps());
  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));

  const adaptDocumentProps = (data: DocumentPropsModelService) => {
    let docProps = documentPropsAdapter(data);

    setDocumentProperties(docProps);
  };

  useEffect(() => {
    if (incidence) {
      getProject(incidence.projectId).then((response) => {
        if (response.status === 200) {
          setProject(completeProjectAdapter(response.data));
        }
      });
    }
  }, [incidence]);

  // Set order editable to select profiles (Commercial, Admin and hmy user)
  useEffect(() => {
    if (currentUser && project) {
      const projectUsersEditable = project.users.filter((user) => user.profileName.toLowerCase() === 'commercial');
      setIsEditable(
        projectUsersEditable.findIndex((user) => user.id === currentUser.id) !== -1 ||
          IsAdmin(currentUser) ||
          currentUser.hmy
      );
    }
  }, [currentUser, project]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (incidence) {
        const fetchedFiles = [];

        for (const file of incidence.files) {
          try {
            const response = await getDocument(file.fileId);
            const documentData = modalDocumentAdapter(response.data);
            fetchedFiles.push(documentData);
          } catch (error) {
            console.error(`Error fetching document ${file.fileId}:`, error);
          }
        }

        setFiles(fetchedFiles);
      }
    };

    fetchFiles();
  }, [incidence?.files]);

  const removeImage = (blobId: string) => {
    deleteDocument(blobId)
      .then(() => {
        setFiles(files.filter((file: any) => file.blobId !== blobId));
        dispatch(setMessage(SuccessMessage('success.DeleteImage', true)));
      })
      .catch(() => {
        dispatch(setMessage(ErrorMessage('error.DeleteImage', true)));
      });
  };

  // Helper function to process each file and create a DTO
  const processFile = async (file: IncidenceFileAddObject, projectId: string): Promise<any> => {
    const base64 = await getBase64(file.file!); // Get base64 asynchronously
    return {
      ...file,
      data: (base64 as string).split(',')[1],
      projectId,
    };
  };

  const createNewIncidenceBlob = async (files: IncidenceFileAddObject[]) => {
    if (incidence) {
      const projectId = incidence.projectId;

      try {
        // Process files asynchronously and create DTOs
        const definitiveRequest: CreateInboxBlobDto[] = await Promise.all(
          files.map((file) => processFile(file, projectId))
        );

        // Filter normal and big files
        return definitiveRequest;
      } catch (error) {
        dispatch(setMessage(ErrorMessage('error.UploadingFile', true)));
        return [];
      }
    }
  };

  const handleAddFile = async (files: any) => {
    const filesToAdd = await files.reduce(async (totalPromise: Promise<IncidenceFileAddObject[]>, file: any) => {
      // Resolve the accumulated value (total)
      const total = await totalPromise;

      try {
        const response = await getValidName(file.name);
        if (response.status === 200 && documentProperties) {
          total.push({
            file,
            name: response.data,
            date: new Date(),
          });
        }
      } catch (error) {
        // handle error
      }

      return total;
    }, Promise.resolve([])); // Initial value is a resolved promise with an empty array

    if (filesToAdd.length > 0) {
      try {
        const blobs = await createNewIncidenceBlob(filesToAdd);
        mutationAddImage.mutate(blobs, {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CreateIncidenceImage', true)));
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CreateIncidenceImage', true)));
          },
        });
      } catch (error) {
        // handle error
      }
    }
  };

  const handleSendComment = () => {
    if (incidenceid && newComment.trim() !== '') {
      mutationCreateComment.mutate(
        { incidenceId: incidenceid, comment: newComment },
        {
          onSuccess: () => {
            dispatch(setMessage(SuccessMessage('success.CreateComment', true)));
            setNewComment('');
          },
          onError: () => {
            dispatch(setMessage(ErrorMessage('error.CreateComment', true)));
          },
        }
      );
    }
  };

  const handleUpdateIncidenceStatus = (incidenceStatusId: string) => {
    if (incidenceid && incidenceStatusId && isEditable) {
      mutationUpdateIncidenceStatus.mutate({ incidenceId: incidenceid, incidenceStatusId });
    }
  };

  const handleSelectedDocumentToSee = async (documentId: string) => {
    try {
      const response = await getDocument(documentId);
      setSelectedDocument(modalDocumentAdapter(response.data));
      setOpenDialog(true);
    } catch (error) {}
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
    setSelectedDocument(null);
  };

  useAsync(
    getDocumentProps,
    adaptDocumentProps,
    () => {},
    () => {},
    []
  );

  if (isLoading) {
    return <LoadComponent />;
  }

  if (!incidence) {
    return null;
  }

  return (
    <Box className="incidence-container">
      <Box style={{ display: 'flex', justifyContent: 'space-between', columnGap: '20px', width: '85%' }}>
        <Box className="incidence-title" onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize="inherit" />
          <Typography className="incidence-title-text">
            {matches ? null : <FormattedMessage id="incidence" />}
            <span className="incidence-title-text-incidence">{incidence.number}</span>
          </Typography>
        </Box>
      </Box>
      <Box className="incidence-body">
        <Box className="incidence-main">
          <span>
            <FormattedMessage id="orderIncidenceSubtitle" />
          </span>
          <div className="incidence-description">{incidence.description}</div>

          <span>
            <FormattedMessage id="comments" />
          </span>
          <div className="incidence-comments">
            <div className="incidence-comments-add">
              <HmyInputTextarea value={newComment} setValue={setNewComment} title="addComment" maxLength={500} />
              <div className="incidence-comments-add-buttons">
                <HmyBtn
                  color="primary"
                  title="btn.save"
                  fullwith={false}
                  filled
                  disabled={newComment.trim() === ''}
                  width={200}
                  handleClick={handleSendComment}
                />
                <HmyBtn
                  color="secondary"
                  title="btn.cancel"
                  fullwith={false}
                  disabled={newComment === ''}
                  width={200}
                  handleClick={() => setNewComment('')}
                />
              </div>
            </div>
            {incidence.comments.map((comment, index) => (
              <IncidenceComment data={comment} odd={index % 2 === 0} />
            ))}
          </div>
        </Box>
        <Box className="incidence-info">
          <span>
            <FormattedMessage id="status" />
          </span>
          {isEditable ? (
            <IncidenceStatus
              handleUpdateIncidenceStatus={handleUpdateIncidenceStatus}
              statusNumber={incidence ? parseInt(incidence.statusNumber) : 0}
            />
          ) : (
            <IncidenceStatusText statusNumber={incidence.statusNumber} />
          )}
          {incidence.lines.length > 0 && (
            <>
              <span className="incidence-modal-section-text">
                <FormattedMessage id="orderIncidenceArticles" />
              </span>
              <List className="incidence-articles-list">
                {incidence.lines.map(({ saleOrderLineId, name }) => (
                  <ListItem key={saleOrderLineId}>
                    <ListItemIcon sx={{ minWidth: '20px', height: '20px', width: '20px' }}>
                      <CheckBoxOutlinedIcon
                        sx={{ height: '20px', width: '20px', '& .MuiSvgIcon-root': { fontSize: '20px' } }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={name} sx={{ '& .MuiListItemText-primary': { fontSize: '16px' } }} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          <span className="incidence-modal-section-text">
            <FormattedMessage id="orderIncidenceImages" />
          </span>
          {files.length > 0 && (
            <div className="incidence-image-list">
              {files.map((file: any) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    key={file.blobId}
                    onClick={() => handleSelectedDocumentToSee(file.blobId)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={file.url} />
                  </div>
                  <span onClick={() => removeImage(file.blobId)} style={{ cursor: 'pointer' }}>
                    <FormattedMessage id="remove" />
                  </span>
                </div>
              ))}
            </div>
          )}
          {documentProperties && documentProperties.maxFiles && files.length < documentProperties.maxFiles && (
            <Box className="incidence-file-add-container">
              <HmyInboxFileZone
                customMaxFiles={documentProperties.maxFiles ? documentProperties.maxFiles - files.length : undefined}
                documentProperties={documentProperties}
                files={[]}
                setFiles={(newFiles) => handleAddFile(newFiles)}
              />
            </Box>
          )}
        </Box>
      </Box>
      {selectedDocument !== null && (
        <HmyModalFileViewer
          isOpen={openDialog}
          canEdit={false}
          file={selectedDocument}
          handleClose={handleCloseModal}
          hanldeClickVisible={() => setOpenDialog(true)}
        >
          <HmyImageViewer document={selectedDocument} />
        </HmyModalFileViewer>
      )}
    </Box>
  );
};

export default Incidence;
