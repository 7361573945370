import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Input, TextField } from '@mui/material';
import { FC } from 'react';
import { HmyTableRow } from 'src/components';
import { CurrentUser } from 'src/models';
import { BasicOrderLines } from 'src/models/orders';
import './hmy-table-row-articles.scss';
import { formatPrice } from '../../format-price.helpers';
import { BasicCatalogueLineModelService } from 'src/services/catalogue';
import { ar } from 'date-fns/locale';
import ImageArticle from '../../hmy-modal-order/image-article';
import PdfArticle from '../../hmy-modal-order/pdf-article';
import { FormattedMessage } from 'react-intl';

type HmyTableRowArticlesProps = {
  isFirst: boolean;
  isLast: boolean;
  article: BasicOrderLines | BasicCatalogueLineModelService;
  currentUser: CurrentUser;
  editable?: boolean;
  updateOrderData: (article: BasicOrderLines | BasicCatalogueLineModelService) => void;
  removeArticle: (id: string, color: string) => void;
};

const HmyTableRowArticles: FC<HmyTableRowArticlesProps> = ({
  isFirst,
  isLast,
  article,
  currentUser,
  editable,
  updateOrderData,
  removeArticle,
}) => {
  const minQuantity = article.minQuantity ? article.minQuantity : 0;
  return (
    <HmyTableRow isFirst={isFirst} isLast={isLast}>
      <Box className="articles-table-row-element" style={{ width: '15%', justifyContent: 'start', cursor: 'pointer' }}>
        {article.reference} {article.color}
      </Box>
      <Box className="articles-table-row-element" style={{ width: '10%', justifyContent: 'start', cursor: 'pointer' }}>
        {article.articleFiles && article.articleFiles.length > 0 && article.articleFiles[0].isImage && (
          <ImageArticle thumbnail={article.articleFiles[0].urlThumbnail} imageId={article.articleFiles[0].fileId} />
        )}
      </Box>
      <Box className="articles-table-row-element" style={{ width: '30%', justifyContent: 'start', cursor: 'pointer' }}>
        {article.referenceDesc}
        <br />
        {article.colorDesc}
      </Box>
      {editable ? (
        <Box
          className="articles-table-row-element"
          style={{ width: '15%', justifyContent: 'center', cursor: 'pointer', columnGap: '10px' }}
        >
          <Box
            onClick={() =>
              article.quantity > 0 &&
              updateOrderData({
                ...article,
                quantity:
                  typeof article.quantity !== 'undefined' && article.quantity > minQuantity ? article.quantity - 1 : 0,
              })
            }
          >
            <RemoveIcon />
          </Box>
          <TextField
            value={article.quantity}
            variant="filled"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { min: minQuantity, step: '1' } }}
            size="small"
            style={{ width: '100px' }}
            sx={{ '& input': { paddingTop: '4px', textAlign: 'end' } }}
            onChange={(e) =>
              updateOrderData({
                ...article,
                quantity: isNaN(Number(e.target.value))
                  ? article.quantity
                  : Number(e.target.value) >= minQuantity
                  ? Number(e.target.value)
                  : 0,
              })
            }
          />

          <Box
            onClick={() =>
              updateOrderData({
                ...article,
                quantity:
                  typeof article.quantity !== 'undefined'
                    ? article.quantity + (article.quantity >= minQuantity ? 1 : minQuantity)
                    : 0,
              })
            }
          >
            <AddIcon />
          </Box>
          {minQuantity && minQuantity !== 0 ? (
            <div>
              <FormattedMessage id="msg.minQuantity" values={{ value: minQuantity }} />
            </div>
          ) : null}
        </Box>
      ) : (
        <Box
          className="articles-table-row-element"
          style={{ width: '15%', justifyContent: 'center', cursor: 'pointer' }}
        >
          {article.quantity}
        </Box>
      )}
      <Box className="articles-table-row-element" style={{ width: '15%', justifyContent: 'end', cursor: 'pointer' }}>
        {formatPrice(article.salePrice)}
      </Box>
      <Box
        className="articles-table-row-element"
        style={{
          width: '15%',
          alignItems: 'center',
          justifyContent: 'end',
          paddingRight: '30px',
          boxSizing: 'border-box',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {formatPrice(article.salePrice * article.quantity)}
        {editable && (
          <Box
            sx={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
            onClick={() => removeArticle(article.reference, article.color)}
          >
            <CloseIcon style={{ fontSize: '16px' }} />
          </Box>
        )}
      </Box>
    </HmyTableRow>
  );
};

export default HmyTableRowArticles;
