import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { HMY_INPUT_FILE_ID, HmyForm, HmyInboxFileZone, LoadComponent } from 'src/components';
import { useFetchAndLoad } from 'src/hooks';
import { DocumentProps } from 'src/models';
import { CHUNK_SIZE, MAX_SIZE, blobServices } from 'src/services';
import { formatDate } from 'src/utilities';
import InboxFileAddForm, { InboxNewFileForm } from './inbox-file-add-form';
import './inbox-file-add.scss';
export type InboxFileAddObject = {
  file: File;
  name: string;
  description: string;
  date: Date;
  documentType: string;
  isBigFile: boolean;
  totalCount: number;
};
type InboxFileAddProps = {
  documentOptions: DocumentProps;
  createNewInboxBlob: (files: InboxFileAddObject[]) => void;
};

const InboxFileAdd: FC<InboxFileAddProps> = ({ documentOptions, createNewInboxBlob }) => {
  const { loading, callEndpoint } = useFetchAndLoad();

  const [files, setFiles] = useState<File[]>([]);

  //LargeFile
  const [values, setValues] = useState<InboxNewFileForm[]>([]);

  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));

  const handleAddDocument = async () => {
    const filesToAdd = await files.reduce(async (totalPromise: Promise<InboxFileAddObject[]>, file) => {
      // Resolve the accumulated value (total)
      const total = await totalPromise;

      const currentValue = values.find((value) => value.finalName === file.name);
      if (currentValue) {
        try {
          const response = await getValidName(file.name);
          if (response.status === 200) {
            total.push({
              file,
              name: response.data,
              description: currentValue.description,
              date: new Date(currentValue.date),
              documentType: currentValue.documentType,
              isBigFile: file.size > MAX_SIZE,
              totalCount:
                file.size % CHUNK_SIZE === 0 ? file.size / CHUNK_SIZE : Math.floor(file.size / CHUNK_SIZE) + 1,
            });
          }
        } catch (error) {
          // handle error
        }
      }

      return total;
    }, Promise.resolve([])); // Initial value is a resolved promise with an empty array

    if (filesToAdd.length > 0) {
      try {
        await createNewInboxBlob(filesToAdd);
        setFiles([]);
      } catch (error) {
        // handle error
      }
    }
  };

  const handleResetForm = () => {
    setValues(values.map((value) => ({ ...value, description: '', date: formatDate(new Date()), documentType: '' })));
  };

  const handleCancelForm = () => {
    setValues([]);
    setFiles([]);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setValues(
        files.map((file) => ({
          finalName: file.name,
          name: file.name,
          size: file.size,
          description: '',
          date: formatDate(new Date()),
          documentType: '',
          isBigFile: false,
        }))
      );
    }
  }, [files]);

  const setFilesNewValues = (newValues: InboxNewFileForm, index: number) => {
    const newData = [...values];
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...newValues,
    });
    setValues(newData);
  };

  return loading ? (
    <LoadComponent />
  ) : (
    <Box className="inbox-file-add-container">
      <HmyInboxFileZone customMaxFiles={3} documentProperties={documentOptions} files={files} setFiles={setFiles} />
      {files.length > 0 && values.length > 0 ? (
        <HmyForm
          submitFormDisabled={values.some((file) => file.description === '')}
          handleResetForm={handleResetForm}
          handleSubmitForm={handleAddDocument}
          handleCancelForm={handleCancelForm}
        >
          {values.map((value, index) => (
            <Box className="hmy-inbox-file-zone-selected" key={index}>
              <Box className="hmy-inbox-file-zone-selected-title">
                <Typography className="hmy-inbox-container-dropzone-text">{value.finalName}</Typography>
                <DeleteIcon
                  onClick={() => setValues(values.filter((_, valueIndex) => valueIndex !== index))}
                  color="error"
                  style={{ cursor: 'pointer' }}
                  fontSize="inherit"
                />
              </Box>

              <InboxFileAddForm values={value} setValues={(newValue) => setFilesNewValues(newValue, index)} />
            </Box>
          ))}
        </HmyForm>
      ) : null}
    </Box>
  );
};

export default InboxFileAdd;
