import { Box, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { es, fr, enGB, pt, tr } from 'date-fns/locale';

import './hmy-calendar-filter.scss';

// import required css from library
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';

type HmyCalendarFilterProps = {
  title: string; //Esto tiene que ser el id de la traducción
  date: Date;
  setDate: (date: Date) => void;
  position: 'left' | 'right';
};

const HmyCalendarFilter: FC<HmyCalendarFilterProps> = ({ title, date, setDate, position }) => {
  const currentUser = useSelector(currentUserSelector);
  const languages: Record<string, any> = {
    es: es,
    fr: fr,
    en: enGB,
    pt: pt,
    tr: tr,
  };
  useEffect(() => {
    registerLocale(currentUser.language, languages[currentUser.language]);
  }, [currentUser.language]);

  //Hooks
  const newRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  //Functions
  const handleChange = (value: Date | null) => {
    if (value !== null) {
      setIsOpen(false);
      setDate(value);
    }
  };

  const toggleOpenCalendar = () => {
    setIsOpen(!isOpen);
  };

  function closeCalendar() {
    setIsOpen(false);
  }

  function handleGlobalClick(e: MouseEvent) {
    const curr: any = newRef.current;
    if (curr && !curr.contains(e.target)) {
      closeCalendar();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleGlobalClick);
    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  });

  return (
    <div className={`hmy-date-container hmy-date-container-${position}`} ref={newRef}>
      <Box
        className={`hmy-calendar-filter-container hmy-calendar-filter-container-${position}`}
        onClick={(e) => toggleOpenCalendar()}
        id={`date-box-${position}`}
      >
        <Typography className="hmy-calendar-filter-text">
          <FormattedMessage id={title} />
        </Typography>
        <Typography className="hmy-calendar-filter-text">
          <FormattedDate value={date} />
        </Typography>
      </Box>
      {isOpen && (
        <DatePicker
          locale={currentUser.language}
          className="hmy-calendar-filter-picker"
          selected={date}
          onChange={(e) => handleChange(e)}
          inline
        />
      )}
    </div>
  );
};

export { HmyCalendarFilter };
